<template>
  <div>
    <v-card class="mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-alert border="top" colored-border type="info" elevation="2">
              <b>Relación de aspecto:</b> 3:1 <br />
              <b>Tamaño recomendado:</b> 1800x600 o superior
            </v-alert>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col v-for="item in banners" :key="item.id" cols="12" sm="3">
            <v-card>
              <v-img
                :src="getBanner(item.fileName)"
                class="white--text align-end"
              >
              </v-img>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  v-if="item.fileName"
                  :href="getBanner(item.fileName)"
                  target="_blank"
                >
                  <v-icon>mdi-cloud-download</v-icon>
                </v-btn>

                <v-btn icon :href="getBanner(item.fileName)" target="_blank">
                  <v-icon>mdi-share</v-icon>
                </v-btn>

                <v-btn icon @click="deleteBanner(item.fileName)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!--
    <v-fab-transition>
      <v-btn color="red" fab dark bottom right class="v-btn--example">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    -->
    <BannerAdd @saved="getBanners()" />
  </div>
</template>

<script>
import BannerService from "../../api/BannerService";
import Bugsnag from "@bugsnag/js";
import BannerAdd from "../../components/admin/banners/BannerAdd";

export default {
  components: {
    BannerAdd
  },
  data() {
    return {
      banners: []
    };
  },
  methods: {
    getBanners() {
      BannerService.getBanners()
        .then(response => {
          this.banners = response.data;
          console.log(this.banners);
        })
        .catch(() => {
          Bugsnag.notify("No se cargaron los banners");
        }); //fin get Banners
    },
    getBanner(fileName) {
      return BannerService.getBanner(fileName);
    },
    deleteBanner(fileName) {
      BannerService.deleteBanner(fileName)
        .then(() => {
          this.getBanners();
        })
        .catch(() => {
          Bugsnag.notify("no se pudo borrar el banner");
        });
    }
  },
  mounted() {
    this.getBanners();
  }
};
</script>
