<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <!--
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Nuevo Banner
        </v-btn>
        -->
        <v-fab-transition>
          <v-btn
            color="red"
            fab
            dark
            bottom
            right
            class="v-btn--example"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <v-form>
        <v-card>
          <v-card-title>
            <span class="headline">Crear nuevo</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Selecciona un imagen"
                    prepend-icon="mdi-camera"
                    v-model="file"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
            <small>* campos requeridos</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="save()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
//import BannerService from "@/api/BannerService";
export default {
  data() {
    return {
      file: [],
      url: process.env.VUE_APP_API,
      dialog: false,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      dtoSave: {},
      rules: [
        value =>
          !value || value.size < 2000000 || "Imagen debe pesar menos de 2 MB!"
      ]
    };
  },
  methods: {
    handleFileUpload(e) {
      console.log(e.target);
      this.file = e.target.files[0];
    },
    save() {
      //const data = JSON.parse(JSON.stringify(this.dtoSave));
      //console.log(data);
      const formData = new FormData();
      formData.append("image", this.file);
      axios
        .post(this.url + "/configuracion/banners", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response);
          this.dialog = false;
          this.$emit("saved", "someValue");
        })
        .catch(error => {
          console.log(error);
        });
    }
    //nuevo
  },
  created() {
    //this.fetchData();
  }
};
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
.v-btn--example {
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 16px 16px 16px 16px;
}
</style>
